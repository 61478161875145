import React, { useEffect, useState } from 'react'
import StandardHeader from '../../Molecules/StandardHeader/StandardHeader'
import Axios from 'axios'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { toDateFromUtc } from '../../toDateFromUtc'

export const Payroll = () => {
  const [year, setYear] = useState<number>()
  const [years, setYears] = useState<number[]>()

  const [month, setMonth] = useState<number>()
  const [months, setMonths] = useState<{ month: number; name: string }[]>()

  const [income, setIncome] = useState(0)
  const [total, setTotal] = useState(0)
  const [onCard, setOnCard] = useState(0)
  const [nonCard, setNonCard] = useState(0)

  const [salary, setSalary] = useState(0)
  const [advances, setAdvances] = useState(0)
  const [gibddFines, setGibddFines] = useState(0)
  const [bonuses, setBonuses] = useState(0)
  const [idles, setIdles] = useState(0)
  const [fines, setFines] = useState(0)

  const [page, setPage] = useState(1)

  const [trips, setTrips] = useState<
    {
      id: string
      type: number
      dateTimeFrom: string
      completed: boolean

      contractorNameFrom: string
      contractorObjectNameFrom: string
      contractorObjectAddressFrom: string

      contractorNameTo: string
      contractorObjectNameTo: string
      contractorObjectAddressTo: string
      payment: number
    }[]
  >()
  const [pages, setPages] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [from, setFrom] = useState<Date>()
  const [to, setTo] = useState<Date>()

  const [salaryHidden, setSalaryHidden] = useState(true)

  useEffect(() => {
    const loadYears = async () => {
      const years = await Axios.get<number[]>('/api/v1/payrolls/years')

      const year = years.data[years.data.length - 1]

      setYears(years.data)
      setYear(year)
    }

    loadYears()
  }, [])

  useEffect(() => {
    const monthsNames = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сениябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ]

    const loadMonths = async () => {
      if (!year) {
        return
      }

      const months = await Axios.get<number[]>(
        `/api/v1/payrolls/months/${year}`
      )

      const monthsData = new Array<{ month: number; name: string }>()

      for (const month of months.data) {
        monthsData.push({ month: month, name: monthsNames[month - 1] })
      }

      const month = months.data[months.data.length - 1]

      setMonths(monthsData)
      setMonth(month)
      updateFromTo(month, year)
    }

    loadMonths()
  }, [year])

  useEffect(() => {
    if (!year || !month) {
      return
    }

    const loadPayroll = async () => {
      const payroll = await Axios.get<{
        total: number
        income: number
        onCard: number
        nonCard: number
        salary: number
        advances: number
        gibddFines: number
        fines: number
        bonuses: number
        idles: number
      }>(`/api/v1/payrolls/${year}/${month}`)

      setTotal(payroll.data.total)
      setIncome(payroll.data.income)
      setOnCard(payroll.data.onCard)
      setNonCard(payroll.data.nonCard)
      setSalary(payroll.data.salary)
      setAdvances(payroll.data.advances)
      setBonuses(payroll.data.bonuses)
      setIdles(payroll.data.idles)
      setGibddFines(payroll.data.gibddFines)
      setFines(payroll.data.fines)
    }

    loadPayroll()
  }, [year, month])

  const updateFromTo = (month?: number, year?: number) => {
    if (month && year) {
      const from = new Date(`${year}-${month}-01 00:00:00.000Z`)
      const to = new Date(
        `${year}-${month}-${new Date(2022, 4, 0).getDate()}  00:00:00.000Z`
      )

      setFrom(from)
      setTo(to)
    }
  }

  useEffect(() => {
    const loadTrips = async () => {
      setIsLoading(true)

      if (!from || !to) {
        return
      }

      try {
        const trips = await Axios.get<{
          items: {
            id: string
            type: number
            dateTimeFrom: string
            completed: boolean

            contractorNameFrom: string
            contractorObjectNameFrom: string
            contractorObjectAddressFrom: string

            contractorNameTo: string
            contractorObjectNameTo: string
            contractorObjectAddressTo: string
            payment: number
          }[]
          pages: number
        }>(
          `/api/v1/trips?page=${page}&from=${from?.toISOString() || ''}&to=${
            to?.toISOString() || ''
          }`
        )

        setTrips(trips.data.items)
        setPages(trips.data.pages)
      } finally {
        setIsLoading(false)
      }
    }

    loadTrips()
  }, [page, from, to])

  return (
    <>
      <StandardHeader iconClass="fas fa-ruble-sign" title="Расчётные листы" />
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col">
            <div className="form-row">
              <div className="col">
                <select
                  className="form-control"
                  placeholder="Месяц"
                  onChange={(_) => {
                    const month = parseInt(_.target.value)
                    setMonth(month)
                    updateFromTo(month, year)
                  }}
                >
                  {months?.map((_) => (
                    <option selected={_.month === month} value={_.month}>
                      {_.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col">
                <select
                  className="form-control"
                  placeholder="Год"
                  onChange={(_) => {
                    const year = parseInt(_.target.value)
                    setYear(year)
                    updateFromTo(month, year)
                  }}
                >
                  {years?.map((_) => (
                    <option selected={_ === year}>{_}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col">
            Рейсы, премии, простои
            <br />
            Штрафы, авансы
          </div>
          <div className="col text-right">
            <span className="text-success">
              <NumberFormat
                value={income}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix=" ₽"
              />
            </span>
            <br />
            <span className="text-danger">
              <NumberFormat
                value={advances + fines + gibddFines}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix=" ₽"
              />
            </span>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col">
            <strong>К выплате</strong>
            <br />
            На карту
            <br />
            На руки
          </div>
          <div className="col text-right">
            <strong>
              <NumberFormat
                value={total}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix=" ₽"
              />
            </strong>
            <br />
            <NumberFormat
              value={onCard}
              displayType={'text'}
              thousandSeparator=" "
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
              suffix=" ₽"
            />
            <br />
            <NumberFormat
              value={nonCard}
              displayType={'text'}
              thousandSeparator=" "
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
              suffix=" ₽"
            />
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col">
            <button
              className="btn btn-link btn-block p-0 text-left"
              onClick={() => {
                setSalaryHidden(!salaryHidden)
              }}
            >
              <strong>Оплата за рейсы</strong>
            </button>
          </div>
          <div className="col text-right">
            <strong className="text-success">
              +{' '}
              <NumberFormat
                value={salary}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix=" ₽"
              />
            </strong>
          </div>
        </div>

        {!salaryHidden
          ? trips?.map((_) => (
              <>
                <hr />
                <div className="row">
                  <div className="col">
                    {format(toDateFromUtc(_.dateTimeFrom), 'dd.MM.yyyy HH:mm')}
                  </div>
                  <div className="col">
                    {_.completed ? (
                      <span className="badge rounded-pill bg-success btn-block text-white">
                        Завершён
                      </span>
                    ) : (
                      <span className="badge rounded-pill bg-warning btn-block">
                        Не завершён
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>
                      {_.type === 0 ? (
                        <Link to={`/edit-supply/${_.id}`}>Поставка</Link>
                      ) : _.type === 1 ? (
                        <Link to={`/edit-shipment/${_.id}`}>Перевозка</Link>
                      ) : (
                        <Link to={`/edit-rent/${_.id}`}>Аренда</Link>
                      )}
                    </strong>
                  </div>
                  <div className="col text-center">
                    <strong>
                      {' '}
                      <NumberFormat
                        value={_.payment}
                        displayType={'text'}
                        thousandSeparator=" "
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix=" ₽"
                      />
                    </strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {_.contractorObjectNameFrom}
                    <br />
                    <span className="text-muted">{_.contractorNameFrom}</span>
                    <div hidden={_.type === 2}>
                      <i className="fas fa-arrow-right"></i>
                      <br />
                      {_.contractorObjectNameTo}
                      <br />
                      <span className="text-muted">{_.contractorNameTo}</span>
                    </div>
                  </div>
                </div>
              </>
            ))
          : null}

        {!salaryHidden ? (
          <div className="row">
            <div className="col">
              {!isLoading && pages > 1 ? (
                <nav aria-label="Навигация">
                  <ul className="pagination">
                    {page !== 1 ? (
                      <li className="page-item">
                        <button
                          type="button"
                          className="page-link"
                          onClick={() => setPage(1)}
                        >
                          <span aria-hidden="true">Начало</span>
                        </button>
                      </li>
                    ) : null}

                    {page > 1 ? (
                      <li className="page-item">
                        <button
                          type="button"
                          className="page-link"
                          onClick={() => setPage(page - 1)}
                        >
                          &laquo;
                        </button>
                      </li>
                    ) : null}

                    <li className="page-item active">
                      <span className="page-link">{page}</span>
                    </li>

                    {pages - 2 >= page ? (
                      <li className="page-item">
                        <button
                          type="button"
                          className="page-link"
                          onClick={() => setPage(page + 1)}
                        >
                          {page + 1}
                        </button>
                      </li>
                    ) : null}

                    {pages - 1 === page ? (
                      <li className="page-item">
                        <button
                          type="button"
                          className="page-link"
                          onClick={() => setPage(page + 1)}
                        >
                          {page + 1}
                        </button>
                      </li>
                    ) : null}

                    {pages - 1 > page ? (
                      <li className="page-item">
                        <button
                          type="button"
                          className="page-link"
                          onClick={() => setPage(page + 2)}
                        >
                          {page + 2}
                        </button>
                      </li>
                    ) : null}

                    {pages - 2 > page ? (
                      <li className="page-item">
                        <button
                          type="button"
                          className="page-link"
                          onClick={() => setPage(page + 1)}
                        >
                          &raquo;
                        </button>
                      </li>
                    ) : null}
                    {page < pages - 2 ? (
                      <li className="page-item">
                        <button
                          type="button"
                          className="page-link"
                          onClick={() => setPage(pages)}
                        >
                          <span aria-hidden="true">Конец</span>
                          &raquo;
                        </button>
                      </li>
                    ) : null}
                  </ul>
                </nav>
              ) : null}
            </div>
          </div>
        ) : null}

        <hr />

        <div className="row">
          <div className="col">
            <strong>Премии</strong>
          </div>
          <div className="col text-right">
            <strong className="text-success">
              +{' '}
              <NumberFormat
                value={bonuses}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix=" ₽"
              />
            </strong>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col">
            <strong>Простои</strong>
          </div>
          <div className="col text-right">
            <strong className="text-success">
              +{' '}
              <NumberFormat
                value={idles}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix=" ₽"
              />
            </strong>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col">
            <strong>Штрафы ГИБДД</strong>
          </div>
          <div className="col text-right">
            <strong className="text-danger">
              {' '}
              <NumberFormat
                value={gibddFines}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix=" ₽"
              />
            </strong>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col">
            <strong>Иные штрафы</strong>
          </div>
          <div className="col text-right">
            <strong className="text-danger">
              {' '}
              <NumberFormat
                value={fines}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix=" ₽"
              />
            </strong>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col">
            <strong>Авансы</strong>
          </div>
          <div className="col text-right">
            <strong className="text-danger">
              {' '}
              <NumberFormat
                value={advances}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix=" ₽"
              />
            </strong>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col">
            <div className="form-group">
              <Link to="/main" className="btn btn-block btn-outline-secondary">
                На главную
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
